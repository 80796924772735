import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { rgba } from "polished";
import PageWrapper from "../components/PageWrapper";

import imgIcon from "../assets/image/png/heart-shape.png";
import { Title, Text, Section } from "../components/Core";
import { Container } from "react-bootstrap";

const ContentIcon = styled.div`
  width: 118px;
  height: 118px;
  background-color: ${({ theme }) => rgba(theme.colors.primary, 0.1)};
  border-radius: 500px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 48px;
`;

const ButtonStyled = styled.button`
  min-width: 250px;
  min-height: 60px;
  border-radius: 10px;
  border: ${({ theme }) => `1px solid ${theme.colors.primary}`};
  color: ${({ theme }) => theme.colors.primary};
  font-size: 21px;
  font-weight: 500;
  letter-spacing: -0.66px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  box-shadow: none;
  outline: none;
  padding-left: 20px;
  padding-right: 20px;
  transition: 0.4s;
  &:hover,
  &:focus {
    background: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.light};
    box-shadow: none;
    outline: none;
  }
`;

const NotFoundPage = () => {
  return (
    <>
      <PageWrapper headerDark footerDark>
        <Section bg="black">
          <Container>
            <div className="text-center">
              <ContentIcon>
                <img
                  src={imgIcon}
                  alt="First land of poker, are you lost ?"
                  className="img-fluid"
                />
              </ContentIcon>
              <div>
                <Title variant="hero" color="light">
                  404 Error!
                </Title>
                <Text color="light">
                  The page you are looking for is not available or doesn’t
                  <br className="d-none d-md-block" /> belong to this website!
                </Text>
              </div>
              <div className="mt-5">
                <Link to="/">
                  <ButtonStyled>Go back to home</ButtonStyled>
                </Link>
              </div>
            </div>
          </Container>
        </Section>
      </PageWrapper>
    </>
  );
};

export default NotFoundPage;
